<template>
	<div class="outer-wrapper">

		<Loader :loading="isLoading"/>
		<div class="inner-wrapper" v-if="!isLoading">
			
			<div v-if="!isLoggedIn" class="not-logged-in">
				To save this tour to a trip, you must first <br>
				<button class="button" @click="loginRedirect()">Log in or Sign Up</button> 
			</div>

			<div v-else>
				<!-- There is no specific trip item to replace (trip_item_id query param). Allow the user to select a trip -->
				<div class="heading-section" v-if="selectTripLoaded">
					<h1 class="heading6">Select a Trip</h1>
				</div>
				<div v-if="isLoggedIn" class="logged-in">
					<div>
						<SelectTrip 
							class="trip-select" 
							@continue="saveTourToBasket"
							v-model="selectedTrip"

							@loaded="selectTripLoaded = true"
						/>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>
<style scoped>
	.heading-section {
		margin-bottom: 50px;
	}
	.not-logged-in {
		text-align: center;
	}
	.not-logged-in .button {
		margin-top: 10px;
	}
</style>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import SelectTrip from '@/components/trips/SelectTrip.vue'
	import { tripService } from '@/services/tripService.js';
	import Loader from '@/components/Loader'
	import track from '@/helpers/track.js'

	export default {
		name: 'toursSelectTrip',
		data() {
			return {
				tourID: this.$route.params.tourID ? this.$route.params.tourID : null,

				selectedTrip: this.$route.query.trip_id ? this.$route.query.trip_id : null,

				isLoading: false,
				selectTripLoaded: false,
			}
		},
		components: {
			SelectTrip,
			Loader,
		},
		computed: {
			...mapState({
				basket: state => state.basket,
			}),
			...mapGetters({
				isLoggedIn: 'account/isLoggedIn',
			}),
			newTripSelected() { // Boolean whether the user has changed the selected trip
				return this.selectedTrip && (this.selectedTrip != this.$route.query.trip_id);
			}
		},
		methods: {
			...mapActions({
				addToBasket: "basket/addToBasket",
				updateTripItem: "trip/updateTripItem",
				alertSuccess: "alert/success",
			}),
			async saveTourToBasket(shouldReplace = false) {
				let data = {};

				// Get these values from state (or this page)
				data.trip_id = this.selectedTrip;
				data.type = "tour";
				data.tourID = this.basket.selected.tour.tourID;
				data.departureID = this.basket.selected.tour.departureID;
				data.roomCode = this.basket.selected.tour.roomCode;
				data.date = new Date(this.basket.selected.tour.date).getTime() / 1000; // Unix timestamp
				data.fareKey = this.basket.selected.tour.fareKey;

				this.isLoading = true;

				let addMethod = "existing trip";
				if(!this.newTripSelected && this.replaceTripItem && shouldReplace) {
					addMethod = "replace existing trip item";
				}
				if(!data.trip_id) {
					// Create new trip first
					let response = await tripService.createTrip()
					data.trip_id = response.data.data.id;
					this.alertSuccess("Trip created!");
					addMethod = "new trip";
				}
				
				this.$emit('update:topLayoutSettings', {
					hide: true,
				});

				try {
					track.event("addTourToTrip", {
						tripID: data.trip_id,
						tourID: data.tourID,
						addMethod: addMethod, // Defines if the trip item was added to an existing trip, or a new trip was created, or a trip item was replaced in an existing trip.
					});
				} catch(e) {
					console.error(e);
					this.$rollbar.error("Tracking: " + e);
				}
				// If TripID & TripItemID - update existing item rather than adding to basket
				if(!this.newTripSelected && this.replaceTripItem && shouldReplace) { // If the trip ID has not changed and there is a trip item to replace && shouldReplace param is true
					this.updateTripItem({ tripID: this.selectedTrip, tripItemID: this.replaceTripItem, data: data }) // Remove this trip item from the trip (to replace it with new one)
						.then(response => {
							// Redirect user to the trip page (user is signed in)
							router.replace({ // Replace the current URL with the new one (so back button doesn't go back to this page). We don't want them pressing back after adding to the trip, as it would allow them to add it again, or think that they haven't added it yet.
								name: "Add Manage Trip Item Travellers",
								params: {
									trip_id: this.$route.query.trip_id,
									trip_item_id: this.$route.query.trip_item_id
								},
							});
						});
				} else {
					// Else add to basket as new item.
					this.addToBasket(data)
						.then(response => {
							if(response.data.type != "local") { // Was sent to backend
								// Redirect user to the trip page (user is signed in)
								router.replace({ // Replace the current URL with the new one (so back button doesn't go back to this page). We don't want them pressing back after adding to the trip, as it would allow them to add it again, or think that they haven't added it yet.
									name: "Add Manage Trip Item Travellers",
									params: {
										trip_id: response.data.trip_id,
										trip_item_id: response.data.trip_item_id,
									},
								});
							} else { // Was sent to local storage
								// Send the user to the login screen (with redirect to trips page after logging in to view the trip after it syncs)
								router.push({
									name: 'Login',
									query: {
										redirect: 'my-trips'
									}
								});
							}
						}); 
				}
			},
			loginRedirect() {
				router.push({ name: 'Login', query: { redirect: window.location.pathname }})
			},
		},
		created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					route: { name: 'Tour Details', params: { tourID: this.tourID } },
				},
				right: {
					type: 'none',
				},
			});

			if(!this.basket.selected.tour) { // If state for selected tour is empty (fare select etc)
				// Redirect to tourDetails page so they can select this
				router.push({
					name: "Tour Details",
					params: {
						tourID: this.tourID,
					}
				})
			}

			this.isLoading = false;
		}
	}
</script>

